.note-content {
  grid-row: 2;
  grid-column: 2;
  padding: 0 1rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

@media only screen and (max-width: 600px) {
  .note-content {
    grid-row: 3;
    grid-column: 1;
  }
}
