.textarea {    
    width: 100%;
    height: 100%;
    resize: none;
    background-color: #282c34;
    color: white;
    font-family: inherit;
    font-size: 1.2rem;
    padding: 0.5rem;
}
