.left-bar {
  grid-row: 2;
  grid-column: 1;

  background-color: grey;
  padding: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .left-bar {
    height: 250px;
    overflow-y: scroll;
  }
}
