.App {
  background-color: #282c34;
  font-size: 1.2rem;
  color: white;
  min-height: 100vh;
  display: grid;
}

* {
  box-sizing: border-box;
}
