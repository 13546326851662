.note-item {
  cursor: pointer;
  padding: 0.5rem;
  background-color: #282c34;
  margin: 0.25rem 0;

  display: grid;
  grid-template-columns: auto 1fr auto auto;
  gap: 0.5rem;
  align-items: center;
}

.note-item:hover {
  background-color: darkcyan;
  transition: 0.5s ease-in-out;
}

.note-item:active {
  transform: scale(0.95);
}

.note-item.active-note {
  background-color: darkcyan;
}

.note-item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
