.footer {
  grid-row: 3;
  grid-column: 1 / span 2;
  padding: 0.5rem;
  border-top: 1px solid rgba(64, 64, 64, 0.5);
}

@media only screen and (max-width: 600px) {
  .footer {
    grid-row: 4;
    grid-column: 1;
  }
}
