.input {
  display: block;
  width: 100%;
  font-size: 1.2rem;
  padding: 0.1rem;
}

.input:focus {  
  outline: none;
}
