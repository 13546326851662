.icon-button {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: none;
}

.icon-button:focus {
    outline:none;
}

.icon-button:hover {
    background-color: lightcyan;
    transition: 0.5s ease-in-out;
}

.icon-button:active {
    transform: scale(0.95);
    transition: 0.1s ease-in-out;
}
