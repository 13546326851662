.login-page {
  display: grid;
  grid-template-rows: 0.5fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}

.login {
  display: grid;
  width: 300px;
  background-color: gray;
  text-align: center;
  padding: 1rem;
  align-self: start;
}

.login-buttons {
  margin-top: 1rem;
}
