.button {
  display: block;
  width: 100%;
  font-size: 1.2rem;
  border: none;
  padding: 0.1rem;
  border-radius: 0.2rem;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(190, 190, 190);
  transition: 0.5s background-color ease-in-out;
}

.button:active {
  outline: none;
  transform: scale(0.95);
}
