.add-note-modal {
  display: grid;
  grid-auto-flow: row;
}

.add-note-modal-buttons {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
}
