.modal-mask {
  z-index: 10;
  background-color: rgba(27, 26, 26, 0.8);
  position: fixed;
  display: grid;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-wrapper {
  align-self: center;
  justify-self: center;
  padding: 0.5rem;
}

.modal {
  background-color: gray;
}

.modal-header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  align-items: center;
  border-bottom: 2px solid white;
  padding: 0.5rem;
}

.modal-body {
  padding: 0.5rem;
}

.icon-delete {
  cursor: pointer;
}
